import React from 'react';
import './Modal.css';
import { Img } from 'components';

const ModalRotate = ({ isOpen, onClose, children, className }) => {
  if (!isOpen) return null;

  return (
    <div className={`modal-rotate-overlay text-[16px] ${className}`}>
      <div className="modal-rotate-content">
        {/* <div className="modal-rotate-phone mx-auto mt-2"></div> */}
        <Img
          src={`images/rotatescreen.gif`}
          alt="Phone"
          className="h-auto w-auto p-2 mx-auto"
        />
        <div className="modal-rotate-message mx-auto">{children}</div>
      </div>
    </div>
  );
};

export { ModalRotate };