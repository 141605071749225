import React from 'react';
import './Modal.css';

const ModalInfo = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-info-overlay" onClick={onClose}>
      <div className="modal-info-content max-w-[500px]" onClick={(e) => e.stopPropagation()}>
        <button className="modal-info-close-button" onClick={onClose}>
          &times;
        </button>
        <div className={`flex flex-col gap-2 justify-center mx-auto bg-white-a700 bg-opacity-80 rounded-2xl w-full h-fit p-2`}>
          <div className="flex-1">
            <p className="text-left mb-2">¡Hola! Esta es una demo de un agente de inteligencia artificial que empuja ventas de electrodomésticos en la pagina web de un retail.</p>
            <p className="text-left mb-2">Favor asume que estás en la siguiente situación: Ud está navegando la pagina web porque de alguna forma está interesado en el producto pero no ha tomado decisión. Favor probar si el agente <b>le ayuda a entusiasmarse para tomar la decisión de compra.</b></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalInfo };