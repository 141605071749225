import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

const RECONNECT_INTERVAL = 6000;
const MAX_RECONNECT_ATTEMPTS = 5;
const CONNECTION_TIMEOUT = 30000;

const useWebSocket = () => {
  const socket = useRef(null);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const reconnectAttempts = useRef(0);
  const navigate = useNavigate();

  const fetchSessionId = useCallback(async () => {
    if (!isConnected) {
      console.log('Creando nueva sesión...');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/new-session`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        sessionStorage.setItem('sessionId', data.session_id);
        setSessionId(data.session_id);
      } catch (error) {
        setError(error.message);
        console.error('Error al crear nueva sesión:', error);
        navigate('/');
      }
    }
  }, [isConnected, navigate]);

  const connectSocket = useCallback(() => {
    if (sessionId && !socket.current) {
      socket.current = io(`${process.env.REACT_APP_API_BASE_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}`, {
        path: '/socket.io/',
        transports: ['websocket', 'polling'],
        auth: { session_id: sessionId },
        reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
        reconnectionDelay: RECONNECT_INTERVAL,
        timeout: CONNECTION_TIMEOUT
      });


      socket.current.on('connect', () => {
        console.log(`Conexión Socket.IO abierta para la sesión: ${sessionId}`);
        setIsConnected(true);
        reconnectAttempts.current = 0;
      });

      socket.current.on('disconnect', (reason) => {
        console.log(`Conexión Socket.IO cerrada para la sesión: ${sessionId}`, reason);
        setIsConnected(false);
      });

      socket.current.on('connect_error', (error) => {
        console.error(`Error de conexión Socket.IO para la sesión: ${sessionId}`, error);
        setError('Error de conexión. Intentando reconectar...');
        if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
          setTimeout(() => {
            reconnectAttempts.current += 1;
            console.log(`Intento de reconexión ${reconnectAttempts.current}...`);
            socket.current.connect();
          }, RECONNECT_INTERVAL);
        } else {
          setError('Se alcanzó el máximo de intentos de reconexión. Por favor, recarga la página.');
        }
      });

      socket.current.on('chat_response', (data) => {
        if (data.message === "[*/not-session-id]") {
          console.log('ID de sesión inválido');
          setIsConnected(false);
          sessionStorage.removeItem('sessionId');
          setSessionId(null);
          navigate('/');
        } else {
          setMessageQueue(prev => [...prev, data.message]);
          setIsTyping(true);
        }
      });
      
    }
  }, [sessionId, navigate]);

  useEffect(() => {
    const storedSessionId = sessionStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      fetchSessionId();
    }
  }, [fetchSessionId]);

  useEffect(() => {
    if (sessionId) {
      connectSocket();
    }

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [sessionId, connectSocket]);

  const handleSendMessage = useCallback((message) => {
    if (socket.current && socket.current.connected) {
      socket.current.emit('chat_message', { message: message.text });
    } else {
      setError('No está conectado. Intentando reconectar...');
      socket.current.connect();
    }
  }, []);

  return { 
    handleSendMessage, 
    messageQueue, 
    isTyping, 
    setIsTyping, 
    setMessageQueue, 
    isConnected, 
    setIsConnected,
    error
  };
};

export default useWebSocket;