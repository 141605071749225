import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

const MyImageGallery = ({ images, onImageClick, width = '100vw', height = 'auto' }) => {
  console.log('images  ', images);

  return (
    <div>
      <Swiper
        effect={'fade'}
        spaceBetween={20}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        cursor={'pointer'}
        navigation
        mousewheel={true}
        modules={[Navigation]}
        className="mySwiper rounded-2xl"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} style={{ width, height }} className='flex items-center'>
            <img 
              src={image} 
              alt={`Slide ${index}`} 
              style={{ width: '100%', height: 'auto', objectFit: 'fill', cursor: 'pointer' }} 
              className='rounded-2xl'
              onClick={() => onImageClick(image)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export { MyImageGallery };