import React, { useState, useEffect, useRef } from 'react';
import { Img, ModalInfo } from "components";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { IoMenu, IoClose, IoInformationCircleOutline } from "react-icons/io5";

const RightView = ({ multimedia, renderMultimedia, isMobileAndPortrait, isMediumWith, isLoading }) => {
  const [showTabs, setShowTabs] = useState(false);
  const [hasNewContent, setHasNewContent] = useState(false);
  const mediaEndRef = useRef(null);
  const [isPortrait, setIsPortrait] = useState(isMobileAndPortrait());
  const [isMedium, setIsMedium] = useState(isMediumWith());
  const [isShowModalInfo, setIsShowModalInfo] = useState(false);

  const toggleModalInfo = () => {
    setIsShowModalInfo(!isShowModalInfo);
  };

  const toggleTabs = () => {
    setShowTabs(!showTabs);
    if (hasNewContent) {
      setHasNewContent(false);
    }
  };

  useEffect(() => {
      const handleResize = () => {
          setIsPortrait(isMobileAndPortrait());
          setIsMedium(isMediumWith());
      };
      window.addEventListener('resize', handleResize);
      // Cleanup listener on component unmount
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    if (multimedia && multimedia.length > 0 && (multimedia[multimedia.length - 1].images.length > 0 || multimedia[multimedia.length - 1].videos.length > 0)) {
      //console.log('multimedia', multimedia);
      setShowTabs(true);
      setHasNewContent(true);
    } else if (multimedia && multimedia.length > 0 && multimedia[multimedia.length - 1].images.length === 0 && multimedia[multimedia.length - 1].videos.length === 0) {
      multimedia.pop();
    }
  }, [multimedia]);

  useEffect(() => {
    if (isLoading) {
      setShowTabs(false);
    }
  }, [isLoading]);

  return (
    <div className="flex w-1/2 h-full sm:h-fit px-2.5 sm:w-full sm:px-0 pb-4 pt-4 sm:pt-0 sm:pb-0 md:pb-2">
      <div className='w-full fixed top-0 left-0 z-20 flex flex-col sm:flex-row items-end' >
        <div className="flex flex-row gap-3 w-fit items-center justify-center mr-2">
          <a href="/"><Img
            src={`images/logo.png`}
            alt="Settings"
            className="h-[52px] w-auto p-2"
          /></a>
          <button
            className="rounded-full mt-2"
            onClick={toggleModalInfo}
          > 
            <IoInformationCircleOutline className="m-auto" size={26} />
          </button>
        </div>
        <button
          className="sm:block hidden bg-gray-400 p-2 m-1 rounded-xl ml-auto"
          onClick={toggleTabs}
        > 
          {showTabs ? <IoClose className="m-auto" size={24} /> : <IoMenu className="m-auto" size={24} />}
          
          {hasNewContent && (
            <span className="absolute ml-2 h-3 w-3 bg-amber-500 rounded-full"></span>
          )}
        </button>
      </div>
      <Tabs
        className={`w-full mt-2 sm:mt-12 py-2 sm:py-0 scrollbar-thumb-black-900 scrollbar-track-white-a700 sm:fixed sm:top-0 sm:right-0 sm:h-fit sm:shadow-lg sm:transition-transform sm:duration-300 sm:rounded-3xl ${showTabs ? 'sm:translate-x-0' : 'sm:translate-x-full'}`}
        selectedTabClassName="!text-white-a700 bg-black-900 rounded"
        selectedTabPanelClassName="px-1 py-1 md:px-2 sm:px-4 relative tab-panel--selected"
      >
        <TabList className="mx-4 flex flex-row justify-start items-center sm:mx-0 mt-5 sm:mt-1">
          <Tab className="px-4 py-2 text-sm font-medium uppercase tracking-[0.40px] text-black-900_dd items-center hidden">
            Info
          </Tab>
        </TabList>
        <TabPanel className={`flex flex-col ${(multimedia && multimedia.length > 0) ? "gap-1" : "gap-0"} p-0 bg-white-a700 bg-opacity-80 border border-solid border-black-900_3a rounded-2xl h-[calc(100vh-76px)] sm:h-[calc(100vh-155px)] md:h-[calc(100vh-70px)] sm:mx-1 sm:rounded-2xl scrollbar-thumb-black-900 scrollbar-track-white-a700`}>
          {(multimedia && multimedia.length > 0 && (multimedia[0].images.length > 0 || multimedia[0].videos.length > 0)) ? (
            <div className="w-full h-auto scrollbar-thin overflow-y-auto">
              {renderMultimedia(multimedia)}
            </div>
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <Img
                src={`${(isPortrait) ? "images/conversation-small.jpeg" : ( (isMedium) ? "images/conversation-small.jpeg" : "images/conversation.jpeg" ) }`}
                alt="Container"
                className={`w-full h-full rounded-2xl object-cover py-1 px-1 self-center`}
              />
            </div>
          )}
          <div ref={mediaEndRef} />
        </TabPanel>
      </Tabs>
      <ModalInfo isOpen={!!isShowModalInfo} onClose={toggleModalInfo} />
    </div>
  );
};

export { RightView };