import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";
import debounce from 'lodash.debounce';

const variants = {
  outline: {
    blue_gray_50: "text-gray-700",
  },
  fill: { white_A700: "bg-white-A700 text-blue_gray-200_01" },
};
const sizes = {
  xxs: "px-1",
  xs: "p-2",
  sm: "p-[8px] pb-2",
  md: "p-[10px] pb-2",
};

const TextArea = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      id = "",
      placeholder = "",
      errors = [],
      label = "",
      prefix,
      suffix,
      size = "md",
      variant = "fill",
      color = "white_A700",
      defaultValue,
      ...restProps
    },
    ref,
  ) => {
    const textAreaRef = useRef(null);

    const adjustHeight = () => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    };

    useEffect(() => {
      const handleResize = debounce(adjustHeight, 300);

      window.addEventListener('resize', handleResize);
      adjustHeight(); 
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleDivClick = () => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    };

    return (
      <>
        <div
          className={`${wrapClassName} 
              ${variants[variant]?.[color] || ""} 
              ${sizes[size] || ""}`}
          onClick={handleDivClick}
        >
          {!!label && label}
          {!!prefix && prefix}
          <textarea
            ref={(el) => {
              textAreaRef.current = el;
              if (typeof ref === 'function') {
                ref(el);
              } else if (ref) {
                ref.current = el;
              }
              adjustHeight(); // Adjust height on ref assignment
            }}
            className={`${className} border-0 bg-transparent w-full resize-none overflow-hidden flex mt-3`}
            name={name}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onInput={adjustHeight}
            style={{ minHeight: '22px', lineHeight: '1', padding: '2px' }}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  },
);

TextArea.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md"]),
  variant: PropTypes.oneOf(["outline", "fill"]),
  color: PropTypes.oneOf(["blue_gray_50", "white_A700", "transparent"]),
};

export { TextArea };