import React, { useState } from 'react';
import { Img, Button, CustomIcons, TextArea } from 'components';
import './MessageInput.css';

const MessageInput = ({ onSend, isDisabled }) => {
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage && !isDisabled) {
      onSend(trimmedMessage);
      setMessage(''); 
    }
  };

  return (
    <div className="flex gap-2 mx-auto min-h-[65px] h-auto py-1 px-2 w-full z-10 items-center bg-white-a700 rounded-3xl">
      <TextArea 
        wrapClassName="w-full"
        className="self-end tracking-[0.15px] w-full h-auto"
        placeholder="Escribe aquí..."
        name="question"
        id="question"
        value={message}
        onChange={handleChange}
        onKeyPress={(e) => { 
          if (e.key === 'Enter' && !e.shiftKey && !isDisabled) {
            e.preventDefault(); 
            handleSend();
          }
        }}
        autoFocus
        required
      />
      {!isDisabled ? (
        <Button 
          className=""
          onClick={handleSend}
          disabled={isDisabled}
        >
          <Img src="images/img_adornmentend.svg" alt="Adornmentend" className="h-[44px] w-[44px]" />
        </Button>
      ) : (
        <CustomIcons iconName="wired-outline-74-navigation" backgroundColor="bg-white-a700" size={45} />
      )}
    </div>
  );
};

export { MessageInput };  